import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Typography, Paper, Button, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ThemeColors } from '../Theme'
import { PagedStatementList } from '../models/PagedStatementList'
import { Global } from '../models/Global'
import { Statement } from '../models/Statement'
import { Account } from '../models/Account';

import { StatementView } from '../components/StatementView';
import { CheckProcessingPopover } from '../components/CheckProcessingPopover';

import { RestComponent } from 'react-frontend-utils' 

import { DateUtils, PopupMenu, DateRangeSelector } from 'react-frontend-utils'
import { Currency } from 'react-frontend-utils'
import { ManualStatementCreatePopover } from '../components/ManualStatementCreatePopover';
import { AccountViewPopover } from '../components/AccountViewPopover';
import { OpenInNewTab } from '../App'



export class StatementsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            paddingRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 150,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
       
    };
    
    

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "All", startTime: null}
    ];
    
    _fetchNewestFirst = true;  //initially, fetch newest first

    constructor(props) {
        super(props);
        this.state.statementsPage = null;         //last fetched page - a PagedStatementList
        this.state.searchFromDate = null;           //JsonDateString or null
        this.state.searchToDate = null;            //JsonDateString or null
        this.state.invoiceNoSearch = null;
        this.state.manualStatementCommentOpen = false;
        this.state.manualTriggerAutoStatementGen = false;
        this.state.viewingStatement = null;         //currently viewing statement popover
        this.state.accountPopoverOpen = false;
        this.state.accountInPopover = null;
        this.state.checkProcessingPopoverOpen = false;
    }
    
    
    /**
     * When the page loads, immediately fetch the first page of statements
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("accountChangeEvent", this._accountHasChanged);
        
        //fetch all Statements, first page
        this._fetchStatements();  
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("accountChangeEvent", this._accountHasChanged);
    }

    //callback when account has changed
    _accountHasChanged = () => {
        this._fetchStatements();  
    }
    
    
    //Fetch a specific page, using search fields if set.
    _fetchStatements = (page = 0) => {
        

        let queryString = "?";
        if (Global.accountID)
            queryString += "accountNo=" + Global.accountID + "&"; 
                        
        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
        }
        if (this.state.invoiceNoSearch) {
            queryString += "invoiceNo=" + encodeURIComponent(this.state.invoiceNoSearch) + "&";
        }

        queryString += "page=" + page + "&sortNewestFirst=" + this._fetchNewestFirst;
        
        this.incrementBusy();

        this.secureJSONFetch("/act/statement", {}, this._fetchStatementsCallback, this._fetchErrorCallback, queryString); 
                
    }
    
    //Callback for fetching Statements - response is json of PagedStatementList
    _fetchStatementsCallback = (response) => {
        if (response) {            
            const sl = new PagedStatementList(response);
            this.setState({statementsPage: sl});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    _gotoPayment = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountForPaymentCallback, this._fetchErrorCallback);
    }
    
    _fetchAccountForPaymentCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            let url = "//" + window.location.hostname + ":" + window.location.port + "/payment?act=" + act.accountNo + "&hm=" + act.paymentHmac;
            OpenInNewTab(url);   
        }
    }


 
    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.statementsPage || this.state.statementsPage.isFirst())
            return;
        
        this._fetchStatements(this.state.statementsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.statementsPage || this.state.statementsPage.isLast())
            return;
        
        this._fetchStatements(this.state.statementsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
  
    _refresh = () => {
        this._fetchStatements(this.state.statementsPage ? this.state.statementsPage.pageNo : 0);   //just fetch the same page again
    }
    
    _search = () => {
        this._fetchStatements(0);
    }


    _checkProcessing = (amount, date, checkNo, note) => {
        if (!Global.accountID)
            return;

        this.setState({checkProcessingPopoverOpen: false});
        
        const cp = {amount: amount, checkDate: date, checkNo: checkNo, note: note};

        this.incrementBusy();
        this.secureJSONFetch("/act/transaction/" + Global.accountID + "/check" , {method: "POST", body: JSON.stringify(cp)}, 
        this._postCheckCallback, this._fetchErrorCallback); 

    }


    _postCheckCallback = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Payment Transaction Created", "Switch to the Transactions tab to view the new Transaction for Account " + Global.accountID, 'green');
    }

    _manualStatementOkCallback = (closeDate, comment) => {
        
        const manualTriggerAutoStatementGen = this.state.manualTriggerAutoStatementGen;
        console.log(closeDate, comment);
        this.setState({manualStatementCommentOpen : false, manualTriggerAutoStatementGen: false});

        //If we have a close date, append it to the url
        const closeDateStr = closeDate ? "&closeDate=" + closeDate : "";

        this.incrementBusy();
        if (manualTriggerAutoStatementGen) {
            this.secureJSONFetch("/act/statementAllAuto?auto=true" + closeDateStr, {method: "POST", body: comment},             //"auto" is not used, just needed for closeDate &
                                this._triggerAutoStatementCallback, this._fetchErrorCallback); 
        }
        else {  // single statement
            this.secureJSONFetch("/act/statement?accountNo=" + Global.accountID + closeDateStr, {method: "POST", body: comment}, 
                                this._manualStatementCallback, this._fetchErrorCallback); 
        }
    }

    _manualStatementCallback = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Triggered Manual Statement Creation", "Refresh the Statement list in a few moments to view new Statement for Account " + Global.accountID, 'green');
    }

    _triggerAutoStatementCallback = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Triggered Statement Creation for All Accounts", "This operation may take some time. Refresh the Statement list to view progress", 'green');
    }


    _viewStatement = (id) => {
        this.incrementBusy();
        this.secureJSONFetch("/act/statement/" + id, {}, this._viewStatementCallback, this._fetchErrorCallback); 
    }

    _viewStatementCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const s = new Statement(response);
            this.setState({viewingStatement: s});
        }
    }

    _downloadAsPDF = (statement) => {
        this.secureFileDownload("/act/statement/" + statement.id + "/pdf", 
                                statement.id + ".pdf", 
                                null, 
                                (error) => this.showConfirmAlert("Error", error, 'red'));
    }

    _emailStatement = (statement) => {
        this.incrementBusy();
        this.secureJSONFetch("/act/statement/" + statement.id + "/email", {method: "POST"}, this._emailStatementCallback, this._fetchErrorCallback); 
    }

    _emailStatementCallback = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Success", "Request to email was submitted", 'green');
    }

    //Action items are items for the right popup menu
    _getActionItems = (statement) => {
        
        const actionItems = [];

        let label;
        switch (statement.type) {
            case "INVOICE":
                label =  "Download as a PDF Invoice";
                break;
            case "PAYOUT_NOTICE":
                label =  "Download as a PDF Payout Notice";
                break;
            case "STATEMENT":
                label =  "Download as a PDF Statement";
                break;
            default:
                label = "Download as PDF";
                break;
        }


        actionItems.push({label: label, selectCallback: () => {this._downloadAsPDF(statement);}, icon: null});
        actionItems.push({label: "Email to Billing email receipients", selectCallback: () => {this._emailStatement(statement);}, icon: null});
        return actionItems;
    }

    _showAccount = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountCallback, this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            this.setState({accountInPopover: act, accountPopoverOpen: true});
        }            
    }
    
    //Render a row of the table with the specified Statement. In the Statement is null, render the header row
    _renderRow = (statement, key) => {
        
        //Render the header
        if (!statement) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, width: 180}}>Closing Date</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Statement No</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'center'}}>Account</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Statement Total</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Past Due</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Balance Forward</th>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
      
      
        const dateAndTime = DateUtils.timeFormat(statement.closeDate);
                    
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableDataWithBorder}>
                    <td style={this.styles.tableData}>{dateAndTime}</td>          

                    <td style={{...this.styles.tableData, fontFamily: 'monospace', textAlign: 'right'}}>{statement.invoiceNo}</td>

                    <td style={{...this.styles.tableData, textAlign: 'center', marginRight: 0}}>
                        <Button onClick={() => this._showAccount(statement.accountNo)}>{statement.accountNo}</Button> 
                    </td>

                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(statement.transactionSum)}</td>
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(statement.pastDue)}</td>
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(statement.balanceForward)}</td>

                    <td style={{...this.styles.tableData, paddingRight: 0}}>
                        <div style={{display: 'flex', justifyContent: 'right'}}>           
                            <div style={{marginLeft: 10}}/>
                            <Tooltip title="View">
                                <IconButton onClick={() => this._viewStatement(statement.id)}>
                                    <VisibilityIcon style={{color: ThemeColors.viewBlue}}/>
                                </IconButton>
                            </Tooltip>   
                            <div style={{marginRight: 10}}/>
                            <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsOlive}}/>)}  
                                                menuItems={this._getActionItems(statement)} 
                                                menuTooltipText={"Actions"}/>                         
                        </div>
                    </td>
                </tr>
            </Fragment>
        );
    }
    
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
                                
        const page = this.state.statementsPage;
       
        const showing = (page && page.statements.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.statements.length) : null;            
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}

                <StatementView statement={this.state.viewingStatement} onPayButton={(accountNo) => this._gotoPayment(accountNo)} onClose={() => {this.setState({viewingStatement: null})}}/>        

                <AccountViewPopover isOpen={this.state.accountPopoverOpen} account={this.state.accountInPopover} onClose={() => this.setState({accountPopoverOpen: false})}/>

                <ManualStatementCreatePopover isOpen={this.state.manualStatementCommentOpen} account={this.state.manualTriggerAutoStatementGen ? null : Global.accountID}
                                 okCallback={this._manualStatementOkCallback} cancelCallback={() => this.setState({manualStatementCommentOpen : false})} />    
               
               <CheckProcessingPopover isOpen={this.state.checkProcessingPopoverOpen} okCallback={this._checkProcessing} cancelCallback={() => this.setState({checkProcessingPopoverOpen: false})} /> 

                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'right', alignItems: 'center'}}>
                

                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}


                        {Global.isAGSAccountAdmin() && Global.accountID ? 
                            <Fragment>
                                <Button size='small' onClick={() => this.setState({checkProcessingPopoverOpen: true})} variant="outlined" style={{marginLeft: 10, color: 'green'}} component="label" >
                                    Process Check Payment
                                </Button>
                                <Tooltip title={"Manually Trigger Statement creation from outstanding Transactions for Account " + Global.accountID}>
                                    <Button size='small' color='primary' onClick={() => this.setState({manualStatementCommentOpen : true, manualTriggerAutoStatementGen: false})} variant="outlined" component="label">
                                        Create Manual Statement
                                    </Button>
                                </Tooltip>
                            </Fragment>
                            : null
                        } 
                        {Global.isAGSAccountAdmin() ? 
                            <Tooltip title="Manually Trigger Statement creation for all Accounts with Auto-Statement generation enabled">
                                <Button size='small' color='primary' onClick={() => this.setState({manualStatementCommentOpen : true, manualTriggerAutoStatementGen: true})} variant="outlined" component="label">
                                    Trigger Auto-Statement Generation
                                </Button>
                            </Tooltip>
                            : null
                        } 
           
                </div>
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Search Statements</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="All"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <Tooltip title="Search for specific Statement No. (Invoice No.)">
                                <TextField label="Statement No." inputProps={{style: {fontSize: 14}}}
                                           onChange={(event) => this.setState({invoiceNoSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                        </Grid>

                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Tooltip title="Search and Display Statements below">
                            <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>
                    </div>

                </Paper>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.statements.map((statement, index) => this._renderRow(statement, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(StatementsListPage));

